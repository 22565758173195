.boliche-evento {
  .accordion {
    margin-top: -4rem;
    padding: 6rem 7rem;
    color: #8b1ea6;
    background-color: #e3e3e3;
    border-radius: 6px;

    &::before {
      right: 10%;
    }
  }
  .accordion-one {
    margin-top: -12rem;
    padding: 2.5rem 7rem;
    color: #8b1ea6;
    background-color: $template-purple-green;
    border-radius: 6px;
    display: flex;
    flex-wrap: wrap;
    &::before {
      left: 5%;
      background-color: $template-purple-green;
    }
    h4 {
      position: relative;
      z-index: 1;
      color: #fff;
      font-family: $font-primary;
      font-weight: 900;
      font-size: 22px;
      line-height: 1.12;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      margin-right: 5rem;
    }
  }
  .accordion-title {
    display: flex;
    // margin-bottom: 4rem;
    color: #424242;
    justify-content: space-between;

    h2 {
      font-size: 3.5rem;
      letter-spacing: 0.1em;
      color: $template-purple-color;
      font-family: $font-secondary;
      font-weight: bolder;
    }
  }
  .btn-group-wrapper {
    display: flex;
    align-items: flex-start;
    // justify-content: flex-end;
    flex-wrap: wrap;
    // width: 100%;
  }
  .btn-pill-evento {
    padding: 1rem 2.7rem;
    font-size: 1.4rem;
    letter-spacing: 0.15em;
    color: #fff;
    background-color: #8b1ea6;
    border-radius: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.intro-section {
  padding: 12rem 0 17rem;
  color: #fff;
  background-size: cover;
  &::before {
    right: 20%;
    background-color: #fff;
  }

  h4 {
    display: inline-block;
    padding: 1.2rem 3.7rem;
    margin-bottom: 2.5rem;
    font-size: 1.8rem;
    letter-spacing: 0.2em;
    border: 2px solid #fff;
    border-radius: 6px;
  }

  h2 {
    margin-bottom: 1rem;
    font-size: 4.5rem;
    letter-spacing: 0.1em;
  }

  h3 {
    margin-bottom: 3rem;
    font-size: 2rem;
    letter-spacing: 0.15em;
  }

  .tag-evento,
  a {
    margin-bottom: 2rem;
    padding: 1rem 2rem;
    margin-right: 3rem;
    font-size: 1.4rem;
    letter-spacing: 0.15em;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 10rem;
    font-weight: bold;
    font-family: $font-primary;
    text-transform: uppercase;
    &:hover {
      background-color: #fff;
      color: #8b1ea6;
    }
  }
}

@include mq(md, max) {
  .intro-section {
    background-position: center;
    h2 {
      font-size: 3.5rem;
    }
    h3 {
      font-size: 1.25rem;
    }
    .tag-evento {
      margin-bottom: 1rem;
      margin-right: 1rem;
      padding: 0.7rem 1.7rem;
      font-size: 1.2rem;
    }
  }
  .table-container-fluid {
    padding: 0;
  }
}

@include mq(sm, max) {
  .intro-section {
    background-position: center;
    padding: 6rem 0 16rem;
    h2 {
      font-size: 2rem;
    }
  }
  .boliche-evento {
    .accordion::before {
      right: 8%;
      height: 5rem;
      width: 5rem;
    }
    .accordion-title {
      margin-bottom: 1em;
      h2 {
        font-size: 2.75rem;
      }
      h3 {
        margin-bottom: 2rem;
        font-size: 1.25rem;
      }
    }
    .accordion-one {
      padding: 2.5rem 2rem;
      h4 {
        font-size: 2rem;
      }
      svg {
        width: 200px;
      }
    }
    .accordion-two {
      padding: 3rem 2rem;
    }
  }
}
