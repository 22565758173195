.section-title {
    position: relative;
    margin-bottom: 4rem;

    h2 {
        font-size: 4rem;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-weight: 600;
    }
}

.btn-out.btn-wrapper {
    position: absolute;
    padding: 2rem 0 2rem 5rem;
    left: 70%;
    right: -100vw;
    font-size: 18px;
    border-radius: 8px;
    border: 3px solid $green-color;
}

@include mq(lg, max) {
    .btn-out.btn-wrapper { 
        padding: 1rem 0 3rem 4rem;
    }
}

@include mq(md, max) {
    .section-title {
        margin-bottom: 10rem;

        h2 {
            font-size: 3rem;
        }
    }

    .btn-out.btn-wrapper {
        top: 170%;
        left: 0;
        padding: 1rem 0 3rem 4rem;
    }
}
