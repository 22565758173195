.pagination {
    display: flex;
    // padding: 0 4rem;
    align-items: center;
    width: fit-content;
    margin-left: auto;
    border: 2px solid $light-color-2;
    border-radius: 6px 6px 0 0;
    border-bottom: 0;

    ul,
    .page-numbers {
        position: relative;
        margin-top: 1rem;
        padding: 0 1.5rem 1rem;
        a {
            &:not(:last-child) {
                margin-right: 4rem;
            }
        }
    }
    ul li .MuiPaginationItem-icon {
        font-size: 2.5rem;
    }
    ul li a,
    ul li button,
    .number {
        font-family: Lato;
        font-size: 1.6rem;
        letter-spacing: 0.1em;
        border-radius: 0;
    }

    ul.MuiPagination-ul {
        padding-bottom: 0;
        .Mui-selected {
            background-color: transparent;
        }
        .Mui-disabled {
            opacity: 1;
        }
        li {
            padding: 0 0.5rem;
            a,
            button {
                color: $light-color-2;
                // margin-bottom: 5px;
                font-weight: bold;
            }
        }
        li:first-child {
            .MuiPaginationItem-root {
                padding-right: 15px;
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 2px;
                    height: 100%;
                    background-color: $light-color-2;
                }
            }
        }
        li:last-child {
            .MuiPaginationItem-root {
                padding-left: 15px;
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 2px;
                    height: 100%;
                    background-color: $light-color-2;
                }
            }
        }
    }

    i {
        font-size: 1.4rem;
    }

    .page-left {
        margin-right: 1.5rem;
    }

    .page-right {
        margin-left: 1.5rem;
    }
}

.pagination-wrapper {
    border-bottom: 2px solid $light-color-2;
    margin-bottom: 7rem;
    .container-fluid {
        display: flex;
    }
}

@include mq(sm, max) {
    .pagination {
        margin-right: auto;
        padding: 0 2rem;
        .page-numbers {
            padding: 0 2.5rem 1rem;
        }
        ul,
        .page-numbers {
            padding: 0 1rem 0.5rem;
        }
        ul.MuiPagination-ul {
            padding: 0;
            li {
                padding: 0;
            }
        }
    }
}
