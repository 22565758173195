.table-template {
  .MuiTableCell-root {
    font-size: 14px;
    font-family: "DM Sans", sans-serif;
  }
  .MuiTableHead-root {
    .MuiTableCell-root {
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  .box-atleta {
    display: flex;
    align-items: center;
    .MuiAvatar-root {
      height: 30px;
      width: 30px;
      margin-right: 0.5rem;
    }
    .MuiTypography-subtitle1 {
      font-family: "DM Sans", sans-serif;
      font-weight: 700;
      font-size: 14px;
      line-height: 1;
    }
  }
  .box-atleta.pts {
    display: block;
    text-align: right !important;
  }
}

@include mq(sm, max) {
  .table-template {
    .MuiTableCell-root {
      font-size: 12px;
    }
    .box-atleta {
      .MuiTypography-subtitle1 {
        font-size: 12px;
      }
    }
  }
}
