.feature-background {
    position: relative;
    padding-top: 254px;
    overflow: hidden;

    &::before {
        position: absolute;
        content: "";
        top: 0;
        transform: translateY(-50%);
        left: 30%;
        width: 27rem;
        height: 27rem;
        background: $secondary-dark-color;
        border-radius: 50%;
    }

    &::after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        width: 100%;
        padding-top: 254px;
        background: $primary-color;
        z-index: -1;
    }
}

.feature-boxes .container {
    margin-top: -20rem;
}

.feature-box {
    padding: 4rem;
    border: 4px solid #81a3cf;
    border-radius: 0 8px 8px 8px;
    min-height: 415px;
    display: flex;
    flex-direction: column;
    i {
        margin-bottom: 2.5rem;
        font-size: 4.6rem;
        font-weight: 500;
    }

    h3 {
        font-family: $font-primary;
        font-weight: 900;
        font-size: 2.5rem;
        letter-spacing: 0.01em;
        margin-bottom: 6rem;
    }

    p {
        font-size: 1.8rem;
        font-weight: 500;
        letter-spacing: 0.01em;
        a {
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        &:last-child {
            padding-bottom: 3.5rem;
            border-bottom: 4px solid $primary-color;
        }
    }
    .card-docs {
        margin-top: auto;
    }
}
