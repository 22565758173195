.orgao-content {
    font-size: 16px;
    font-family: $font-secondary;
    letter-spacing: 0.01em;
    h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1{
        margin-bottom: .75em;
        font-weight: 700;
    }
    p{
        font-size: 16px;
        margin-bottom: 1em;
        &.lead{
            font-size: 20px;
        }
    }
    ul{
        list-style: disc;
        list-style-type: disc;
        padding-left: 1.5em;
        ::marker {
            display: block;
            unicode-bidi: isolate;
            font-variant-numeric: tabular-nums;
            text-transform: none;
            text-indent: 0px !important;
            text-align: start !important;
            text-align-last: start !important;
        }
    }
}
