.home {
    .post-group.grid {
        margin-bottom: 7rem;
    }

    .ranking-section {
        margin-top: -11rem;
        z-index: 10;    
    }    

    .feature-boxes {
        margin-bottom: 5rem;
    }

    .post-group.grid {
        margin-top: -2rem;
    }
}