.custom-table {  
  border: 2px solid $light-color-2;
  overflow: auto hidden;

  .table-header,
  .table-row {
    display: flex;
  }

  .table-header {
    padding: 1rem 3rem;
    width: fit-content;
    min-width: 100%;
    background-color: $light-color-2;
    color: #fff;
  }

  .table-row {
    padding: 2rem 3rem;
    color: $dark-color;
  }

  .th {
    padding: 6px 0;

    &:not(:first-child) {
      padding-left: 4rem;
    }

    &:not(:last-child) {
      border-right: 1px dotted #7290cf;
    }
  }

  .td {
    padding: 1.1rem 0;
    border-bottom: 1px dotted $primary-color;

    &:not(:first-child) {
      padding-left: 4rem;
    }

    &:not(:last-child) {
      border-right: 1px dotted $primary-color;
    }
  }

  .download {
    padding: 1rem 1.4rem;
    font-size: 1.2rem;
    color: #fff;
    background-color: $green-color;
    border-radius: 6px 6px 0 0;
  }

  h4 {
    font-size: 1.4rem;
    font-weight: 600;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@include mq(lg, max) {
  .custom-table {
    .td:not(:first-child) {
      padding-left: 2rem;
    }
    .table-row {
      padding: 2rem 1rem;
    }
    .table-header {
      padding: 1rem;
    }
    .td,
    .th {
      min-width: 12rem;
    }
  }

  .card {
    padding: 3rem;
  }
}

.table-dark-template {
  // overflow: auto;
  padding-bottom: 4rem;
  .th,
  .td {
    color: #fff;
    text-align: center;
    font-size: 1.5rem;
    font-family: $font-secondary;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding: 0.5rem 1rem;
    min-width: 70px;
    &:last-child {
      border-right: none;
    }
  }
  .table-header,
  .table-row {
    display: flex;
    padding: 1rem 2.5rem 0;
    margin: 0 6rem;
  }
  .td {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .nome-atleta {
    min-width: 250px;
  }
  .linhas {
    min-width: 120px;
  }
  button.close-linha {
    svg {
      transition: all 150ms ease-in-out;
    }
  }
  button.open-linha {
    svg {
      transition: all 150ms ease-in-out;
      transform: rotate(180deg);
      path {
        stroke: #fff084;
      }
    }
  }
  .row-detalhe {
    background-color: #b2a440;
    padding: 1rem 0;
    // width: fit-content;
    .td:first-child {
      border: none;
    }
    .detalhe-linha-top {
      padding-bottom: 0;
      padding-top: 0;
      .td {
        padding-top: 1rem;
        background-color: #b2a440;
      }
    }
    .detalhe-linha-bottom {
      padding-top: 0;
      .td {
        background-color: #b2a440;
        padding-bottom: 1rem;
        border-bottom: none;
      }
    }
  }
}

.card-atleta {
  min-height: 260px;
  height: auto;
  width: 100%;
  margin-bottom: -30px;
  border-radius: 0 8px 8px 8px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.maior-linha {
    background-color: #668dc1;
  }
  &.maior-serie {
    background-color: #bfab12;
  }
  &.maior-serie,
  &.maior-linha {
    &::before {
      content: "";
      height: 278px;
      width: 278px;
      border-radius: 50%;
      border: 4px solid #1f4f8d;
      position: absolute;
      left: -83px;
      top: -110px;
    }
    &::after {
      content: "";
      height: 218px;
      width: 218px;
      border-radius: 50%;
      border: 4px solid #327fe4;
      position: absolute;
      left: -90px;
      top: 65px;
    }
  }
  .card-content {
    z-index: 2;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .MuiAvatar-root {
    height: 145px;
    width: 145px;
    margin-right: 2rem;
    z-index: 2;
  }
  .card-atleta-info {
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  h4 {
    font-size: 3rem;
    line-height: 1;
    font-family: $font-primary;
    color: #fff;
    font-weight: 900;
    margin-bottom: 1rem;
  }
  a,
  h5 {
    font-size: 2rem;
    line-height: 1;
    font-family: $font-primary;
    color: #1f4f8d;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  a:hover {
    color: #fff;
  }
  .card-pts {
    border: 2px solid #fff;
    border-radius: 5px;
    padding: 0.5rem 2.5rem;
    font-size: 1.8rem;
    font-weight: 700;
  }
}

@include mq(lg, max) {
  .table-dark-template {
    .row-detalhe {
      width: fit-content;
    }
    .table-header,
    .table-row {
      padding: 1rem 0 0;
      margin: 0 4rem;
    }
  }
}

@include mq(md, max) {
  .table-dark-template {
    .table-header,
    .table-row {
      padding: 0;
      margin: 0 1rem;
    }
  }
  .card-atleta.maior-linha {
    margin-bottom: 20px;
  }
}

//tabela partida
.partidas-section {
  padding: 4rem;
}

.partidas-title {
  font-size: 3.5rem;
  color: #1f4f8d;
  font-family: $font-secondary;
  font-weight: bold;
  line-height: 1.1;
  letter-spacing: 0.1em;
  margin-bottom: 2rem;
}
.table-partida {
  border-top: 1px dashed #000000;
  margin-bottom: 5rem;
  font-family: $font-secondary;
  tr {
    vertical-align: middle;
  }
  .border-flag{
    border: 1px solid rgb(125, 125, 125);
  }
  .table-title {
    font-family: "DM Sans";
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.2em;
    color: #253b58;
    text-align: center;
    margin: 2.5rem 0;
  }
  .atleta {
    font-family: $font-secondary;
    font-size: 2.5rem;
    line-height: 1;
    letter-spacing: 0.05em;
    color: #253b58;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .placar {
    padding: 1.8rem 1rem;
    color: #1f4f8d;
    font-size: 2.5rem;
    font-family: $font-secondary;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
  }
  .pts {
    font-family: $font-primary;
    font-weight: 900;
    font-size: 2.5rem;
    line-height: 1;
    text-align: right;
    letter-spacing: 0.1em;
    margin: 0 1.5rem;
  }
  .pts.vencedor {
    padding: 0.5rem 1.6rem;
    background: #278664;
    border-radius: 3px;
    color: #fff;
  }
}

@include mq(lg, max) {
  .table-partida {
    .atleta {
      font-size: 2rem;
    }
    .pts {
      font-size: 2rem;
    }
    .placar {
      font-size: 2rem;
    }
  }
}

@include mq(md, max) {
  .table-partida {
    .atleta {
      font-size: 1.5rem;
    }
    .pts {
      font-size: 1.75rem;
    }
    .placar {
      font-size: 1.5rem;
    }
  }
}
@include mq(sm, max) {
  .partidas-section {
    padding: 1.5rem;
  }
  .table-partida {
    .table-title {
      font-size: 1.25rem;
    }
    .atleta {
      font-size: 1rem;
      img {
        height: 20px;
      }
    }
    .pts {
      font-size: 1.25rem;
      margin: 0 0.3rem;
      &.vencedor {
        line-height: 1.2;
        padding: 0.25rem 0.8rem;
      }
    }
    .placar {
      padding: 0.5rem 0;
      margin: 0;
      font-size: 1rem;
    }
  }
}

//table atletas
.board-body {
  padding: 0 8rem;
  .collapse-detalhes {
    overflow: hidden;
    margin-left: -8rem;
    margin-right: -8rem;
    background-color: #b2a440;
    &.MuiCollapse-entered {
      height: 110px !important;
    }
    .MuiCollapse-wrapper {
      padding: 0 8rem;
    }
  }
  .MuiButton-root {
    font-size: 1.5rem;
    margin: 8px;
    font-family: $font-secondary;
    font-weight: 700;
    border: 2px solid #fff;
    border-radius: 5px;
    color: #fff;
    &.active,
    &:hover {
      border-width: 2px;
      border-color: #fff084;
      color: #fff084;
    }
  }
  .text-accent {
    color: #8ddcf9;
  }
}
.table-atletas {
  padding-bottom: 4rem;
  vertical-align: middle;
  .icon-expand {
    display: none;
    font-size: 1.75rem;
    color: rgb(52, 200, 255);
    svg {
      font-size: 2.2rem;
    }
  }
  .nome-atleta {
    max-width: 180px;
  }
  th,
  td {
    color: #fff;
    text-align: center;
    font-size: 1.5rem;
    font-family: $font-secondary;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding: 0.5rem 1rem;

    &:last-child {
      border-right: none;
    }
  }
  .td-detalhes {
    border: none;
    padding: 0;
    padding-bottom: 10px;
  }
  .MuiCollapse-wrapperInner {
    position: relative;
  }
  .table-detalhes {
    margin: 1.5rem 0;
    position: absolute;
    left: 0;
    top: 0;
    left: 50%;
    right: 50%;
    transform: translate(-50%, 0);
    width: fit-content;
    tr th {
      color: #414141;
      font-weight: bold;
      border-bottom: 1px solid #fff !important;
    }
    tr:first-child td {
      padding-top: 1rem;
    }
    tr:last-child td {
      padding-bottom: 1rem;
      border-bottom: none;
    }
    td,
    th {
      padding-bottom: 1rem;
      padding-top: 1rem;
    }
    // tr td:first-child {
    //   border: none;
    // }
  }
  button.close-linha {
    svg {
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }
  button.open-linha {
    svg {
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      transform: rotate(180deg);
      path {
        stroke: #fff084;
      }
    }
  }
  .link-td-atleta {
    font-weight: 500;
    color: #fff;
    &:hover {
      opacity: 0.8;
    }
  }
}

.collapse-expand {
  background-color: rgba($color: #668dc1, $alpha: 0.5);
  margin: 0 -8rem;
  padding: 0 8rem;
}
.table-expand {
  font-family: $font-secondary;
  .th {
    font-weight: 900;
  }
}
.row-hidden-md {
  display: none !important;
  height: 0;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.row-expand-md {
  display: none !important;
}

.row-show-sm {
  display: none !important;
}

.card-mapa {
  .button-pontos{
    padding: 0.5rem 2.5rem;
    background-color: $light-color-2;
    font-size: 14px;
    font-weight: 600;
    color: $secondary-color;
    letter-spacing: 0.1em;
    border: 2px solid #fff;
    border-radius: 5px;
  }
  h5 {
    font-size: 1.5rem;

    margin-bottom: 2rem;
  }
  .title-mapa {
    font-size: 2.5rem;
    font-weight: 700;
    font-family: $font-secondary;
  }

  .MuiSkeleton-root {
    background-color: rgb(255 255 255 / 11%);
  }
  .table-mapa {
    th,
    td {
      font-family: $font-secondary;
      font-size: 1.5rem;
      color: #fff;
    }
    thead th {
      font-weight: 900;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
    tbody tr:first-child td {
      color: $secondary-light-color;
      font-size: 1.75rem;
      font-weight: 900;
    }
  }
}
@include mq("1100px", max) {
  .board-body {
    padding: 0 4rem;
  }
}
@include mq(lg, max) {
  .board-body {
    padding: 1rem;
    .collapse-detalhes {
      margin-left: -1rem;
      margin-right: -1rem;
      &.MuiCollapse-hidden {
        width: 0;
      }
      .MuiCollapse-wrapper {
        padding: 0 1rem;
      }
    }
  }
  .table-atletas {
    .icon-expand {
      display: inline-flex;
    }
  }
  .col-hide-lg {
    display: none !important;
  }
  .collapse-expand {
    margin-top: -1rem !important;
    margin: 0 -1rem;
    padding: 0 1rem;
  }
  .row-expand-md {
    display: table-row !important;
  }
}
@include mq(md, max) {
  .col-hide-md {
    display: none !important;
  }
  .row-expand-md {
    display: table-row !important;
  }
  .row-show-sm {
    display: table-row !important;
  }
  // .table-expand {
  //   height: 150px;
  // }
}
@include mq(sm, max) {
  .board-body {
    padding: 0;
    .collapse-detalhes {
      margin-left: 0rem;
      margin-right: 0rem;
      .MuiCollapse-wrapper {
        padding: 0 1rem;
      }
      &.MuiCollapse-entered {
        height: 95px !important;
      }
    }
  }
  .collapse-expand {
    margin: 0;
    padding: 0;
  }
  .table-atletas {
    .table-detalhes {
      margin: 1rem 0;
      width: 100%;
    }
    th {
      font-size: 1.25rem;
    }
    td {
      font-size: 1.2rem;
    }
  }
  .col-hide-sm {
    display: none !important;
  }
  .row-show-sm {
    display: table-row !important;
  }
}
