.MuiTooltip-tooltip {
  font-size: 1.1rem !important;
}
.ranking-page {
  .ranking-list-one {
    &::before,
    &::after {
      background: $primary-light-color;
    }
  }

  .select-box {
    max-width: 50rem;
    margin-bottom: 4rem;

    .form-control {
      background-color: $primary-light-color;
      color: $primary-color;
      border-color: $primary-color;
    }

    &::before {
      color: $primary-color;
    }
  }

  .form-search {
    display: block;
    width: 100%;
    // padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 17.4207L13.2542 13.6748M15.2778 8.80954C15.2778 12.6142 12.1935 15.6984 8.38889 15.6984C4.58426 15.6984 1.5 12.6142 1.5 8.80954C1.5 5.00491 4.58426 1.92065 8.38889 1.92065C12.1935 1.92065 15.2778 5.00491 15.2778 8.80954Z' stroke='%23253b58' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 25px 22px;
    font-size: 1.2rem;
    padding: 1rem 5rem 1rem 1.8rem;
    font-size: 2rem;
    background-color: transparent;
    color: #253b58;
    border-color: #253b58;
    border-radius: 5px;
    max-width: 50rem;
  }

  .list-header {
    margin-bottom: 4rem;
  }

  .bg-primary-light {
    padding-bottom: 10rem;
  }

  .ranking-list-one {
    padding-bottom: 10rem;

    &::before,
    &::after {
      content: none;
    }

    .container:first-child {
      background-color: $primary-light-color;
    }
  }

  .container-fluid {
    margin-top: -8rem;
    overflow: auto hidden;
  }

  .ranking-wrapper {
    min-width: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    .btn-load-content {
      font-size: 1.5rem;
      font-weight: 700;
      font-family: $font-primary;
      color: #daebff;
      border-radius: 5px;
      border: 2px solid #daebff;
      padding: 0.5em 1.8em;
    }
  }

  .top-3 {
    padding-bottom: 3rem;
    a {
      color: #fff;
    }
  }
  .top-10 {
    padding-bottom: 8rem;
    background-color: #203147;
    color: #daebff;
    a {
      color: #daebff;
    }
    h2,
    h3 {
      color: #daebff;
    }
    .player-info {
      padding-left: 0;
    }

    .player-number {
      margin-right: 6rem;
    }
  }

  .top-3,
  .top-10 {
    padding-left: 9rem;
    padding-right: 9rem;
    .player-avatar {
      .loading-avatar {
        height: 58px;
        width: 58px;
      }
      .MuiAvatar-root {
        height: 58px;
        width: 58px;

        img {
          max-width: 100%;
          border-radius: unset;
        }
      }
    }
  }

  .top-3 {
    position: relative;
    padding-top: 5rem;
    border-radius: 6px 6px 0 0;
    .player-score {
      h3 {
        color: #fff;
      }
    }
    .text-secondary {
      color: #fff084 !important;
    }
  }

  .player-info {
    padding: 3rem 0 3rem 7rem;
    margin-bottom: 0;
  }

  .player .total-score {
    margin-left: 1rem;
  }

  .ranking-title {
    display: flex;
    position: relative;
    margin-bottom: 3.5rem;
    color: #fff;
    justify-content: space-between;
    z-index: 1;

    h2 {
      font-size: 3rem;
      letter-spacing: 0.1em;
    }

    h6 {
      font-size: 1.4rem;
      letter-spacing: 0.1em;
    }
  }

  .player-name {
    min-width: 15rem;
  }
  .atleta-pts-wrapper {
    min-width: 500px;
  }
  .btn-swipe-view {
    color: #fff;
    min-width: 30px;
    font-size: 1.2rem;
    &.Mui-disabled {
      color: rgba(255, 255, 255, 0.5);
    }
  }
  .MuiSkeleton-root {
    background-color: rgba(255, 255, 255, 0.5);
  }
  label {
    display: block;
    position: unset;
    font-family: $font-secondary;
    margin-left: 1.8rem;
    font-size: 1.5rem;
    letter-spacing: 0.1em;
    color: #253b58;
    font-weight: bolder;
  }
  .select-mui {
    .MuiFormControl-root {
      margin-top: 0.45rem;
    }
    svg {
      font-size: 2rem;
    }
    .MuiSelect-select,
    input {
      padding: 1.325rem 5rem 1rem 1.8rem;
      font-size: 2rem;
      color: #253b58;
    }
    .MuiOutlinedInput-root {
      font-size: 2rem;
      border-radius: 5px;
    }
    fieldset {
      border-color: #253b58;
      legend span {
        padding: 0;
      }
    }
  }
}

.MuiCalendarPicker-root {
  div[role="presentation"] {
    font-size: 1.5rem;
  }
  button {
    font-size: 1.5rem;
  }
}

@include mq("1366px", max) {
  .ranking-page {
    .top-3,
    .top-10 {
      padding-left: 8rem;
      padding-right: 8rem;
    }
    .player-info {
      padding: 3rem 0 3rem 7rem;
    }
    .ranking-list .top-3 .player-number {
      padding: 1rem 2rem 1rem 9.5rem;
    }
  }
}

@include mq(lg, max) {
  .ranking-page .top-10 .player-number {
    margin-right: 5rem;
    margin-left: -5rem;
  }
  .ranking-page {
    .player-info {
      padding: 3rem 0 3rem 1.3rem;
    }
    .ranking-list .top-3 .player-number {
      padding: 1rem 2rem 1rem 3.5rem;
    }
  }
}

@include mq(md, max) {
  .ranking-page {
    .player-info {
      padding: 2rem 0 2rem 2.2rem;
    }
    .atleta-pts-wrapper {
      min-width: unset;
    }
    .top-10 .player-number {
      margin-right: 3rem;
      margin-left: -2rem;
    }
    .top-3,
    .top-10 {
      padding-left: 5rem;
      padding-right: 5rem;
    }
  }
  .ranking-list .top-3 .player-info .player-number {
    padding: 1rem 1rem 1rem 2.5rem;
  }
}

@include mq(sm, max) {
  .ranking-page {
    .container-fluid {
      padding: 0;
    }
    .top-3 {
      padding: 2rem;
      span,
      .player-avatar {
        padding-left: 2rem;
      }
    }
    .top-10 {
      padding: 2rem;
      padding-top: 0;
      .player-number {
        margin-left: 0;
      }
    }
    .player-info {
      padding: 2rem 0 2rem 2rem !important;
    }
    .top-10 {
      .player-info {
        padding: 2rem 0 2rem 0 !important;
      }
    }
    .top-3,
    .top-10 {
      .player-avatar {
        span,
        .MuiAvatar-root {
          height: 45px;
          width: 45px;
        }
      }
    }
    .ranking-list .top-3 .player-number {
      padding: 1rem 2rem 1rem 4rem;
    }

    .btn-swipe-wrapper {
      margin-right: 85px;
      .btn-swipe-view {
        font-size: 1.2rem;
      }
    }
    .btn-swipe-view {
      min-width: 20px;
    }
  }
  // .ranking-page .top-3 {
  //   padding-left: 4rem;
  // }
}
