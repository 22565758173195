.contato-page {
    margin-bottom: 5rem;
    .bg-primary-light {
        padding-bottom: 10rem;
    }
    .card-contato {
        margin-top: -10rem;
        padding-top: 5rem;
        border-radius: 6px 6px 0 0;
        padding-left: 7rem;
        padding-right: 7rem;
        .card-title {
            font-size: 3.25rem;
            margin-bottom: 1.5rem;
            font-family: $font-secondary;
        }
        p {
            font-size: 1.75rem;
            line-height: 1.5;
            margin-bottom: 1.5em;
            a {
                color: #fff;
                &:hover {
                    opacity: 0.8;
                }
            }
        }
        form {
            font-size: 1.5rem;
            .form-group {
                margin-bottom: 1.5rem;
            }
            label {
                font-size: 1.5rem;
                font-weight: 500;
                line-height: 1.5;
                font-family: $font-secondary;
            }
            .form-control {
                border-radius: 2px;
                font-size: 1.5rem;
                padding: 6px 14px;
                line-height: 1.8;
            }
            .form-check-input {
                border-radius: 0;
                &:checked {
                    background-color: $primary-light-color-2;
                    border-color: $primary-light-color-2;
                }
            }
            button{
                font-size: 1.5rem;
                font-family: $font-primary;
                border-width: 2px;
                &:hover{
                    background-color: #fff;
                    color: $primary-color;
                }
            }
        }
    }
}

@include mq(sm, max) {
    .contato-page {
        .card-contato {
            padding-top: 3rem;
            padding-left: 2rem;
            padding-right: 2rem;
            .card-title {
                font-size: 2.75rem;
            }
            p {
                font-size: 1.5rem;
            }
        }
    }
}
