@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Roboto:wght@300;400;500;700&family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 900;
  src: local("Rubik"), local("Rubik"),
    url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nBrXw.woff2)
      format("woff2");
  font-display: swap;
}
.grid-noticias {
  margin-bottom: 3em !important;
  max-width: 1300px;
  margin: 0 auto;
  font-family: "Rubik", sans-serif;
 img{
  opacity: 0.7;
 }

  .post {
    background-color: #000;
    border: 0px solid $primary-color;
    position: relative;
    height: 100%;
    width: 100%;
    min-height: 250px;

    a {
      height: 100%;
      display: flex;
    }
    .card-text {
      position: relative;
      margin-top: auto;
      padding-left: 4em;
      padding-right: 4em;      
      color: rgb(255, 255, 255);
      text-shadow: rgb(0 0 0) 1px 1px 1px;
      text-align: left;
      font-weight: 900;    
    }

    .post-media {
      position: absolute;
      transition: transform 0.2s;
      width: 100%;
      height: 100%;
      object-fit: cover;     
    }
    &:hover {
      .post-media {
        transform: scale(1.2);
      }
    }

    .post-date {
      font-size: 1.2rem;
      font-weight: 900;
    }

    .post-title {
      font-size: 30px;
      color: rgb(255, 255, 255);
      line-height: 1.1;
      font-weight: 900;
      letter-spacing: 1px;
    }
    .title-2 {
      font-size: 20px;
    }
  }
  @media (max-width: 1200px) {
  .post .post-title{
    font-size: 24px;
  }
  }
}
