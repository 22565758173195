.aleta {
  .section-title {
    padding: 8rem 0 27rem;
    h2 {
      font-family: $font-primary;
      font-weight: 900;
    }
  }

  .aleta-info {
    display: flex;
    position: relative;
    z-index: 10;
  }

  .aleta-media {
    margin: 0 5rem 0 0;
    height: 141px;
    width: 141px;
  }

  .aleta-bio {
    font-family: $font-primary;
    text-transform: uppercase;
    h2 {
      font-weight: 900;
      margin-bottom: 1.4rem;
      font-size: 4rem;
      letter-spacing: 0.2em;
    }

    h4 {
      font-size: 2rem;
      font-weight: 900;
      letter-spacing: 0.1em;
    }

    h5 {
      font-size: 1.2rem;
      letter-spacing: 0.1em;
    }
  }

  .aleta-item {
    padding: 0 3rem;
    border-right: 1px solid #fff;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      border: none;
    }
  }

  .right-circle::before {
    background-color: $secondary-dark-color;
  }

  .board:last-child {
    margin-bottom: 8rem;
  }

  .card {
    border-radius: 0px 8px 8px 8px;
    overflow: hidden;
    background-color: $secondary-dark-color;
    border: none;
    padding: 6rem 4rem 5rem 9rem;
    .main-atleta {
      width: 100%;
      &::before {
        content: "";
        height: 278px;
        width: 278px;
        border-radius: 50%;
        border: 4px solid #1f4f8d;
        position: absolute;
        left: -83px;
        top: -110px;
      }
      &::after {
        content: "";
        height: 218px;
        width: 218px;
        border-radius: 50%;
        border: 4px solid #327fe4;
        position: absolute;
        left: -90px;
        top: 65px;
      }
      img {
        position: relative;
        z-index: 2;
      }
      // .card-content {
      //   display: flex;
      //   padding: 5rem 4rem;
      // }
    }
  }
  .btn-load-content {
    font-size: 1.5rem;
    font-weight: 700;
    font-family: $font-primary;
    color: #414141;
    border-radius: 5px;
    border: 2px solid #414141;
    padding: 0.5em 1.8em;
    &:hover {
      color: #bfab12;
      border: 2px solid #bfab12;
      background-color: rgba(255, 241, 132, 0.04);
    }
  }
}
.partidas-section {
  .form-label {
    font-size: 1.5rem;
    padding-left: 2rem;
    margin-bottom: 1rem;
    font-family: $font-primary;
  }
  .form-select {
    padding: 1rem 5.5rem 1rem 1.6rem;
    background-color: transparent;
    color: #474747;
    font-size: 1.5rem;
    border-radius: 6px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 1.75V15.75M8 15.75L15 8.75M8 15.75L1 8.75' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-size: 55px 16px;
    &::placeholder {
      color: #474747;
    }
    option {
      color: #474747;
      border: none;
      border-color: transparent;
    }
  }
}
.board {
  padding: 5rem 9rem 5rem;
  margin-bottom: 2rem;
  background: #414141;
  border-radius: 6px;
  letter-spacing: 0.1em;
  color: #fff;
  font-family: $font-primary;

  .board-top {
    font-family: $font-primary;
    font-weight: bold;
    margin-bottom: 1.2rem;
    font-size: 2rem;
    color: #fff084;
    & a {
      font-family: $font-primary;
      font-weight: bold;
      margin-bottom: 1.2rem;
      font-size: 2rem;
      color: #fff084;
      &:hover {
        color: #e2d151;
      }
    }
  }

  .board-middle {
    margin-bottom: 3.5rem;
    h5 {
      font-family: $font-primary;
      font-size: 1.8rem;
    }
  }
  .board-title {
    font-family: $font-primary;
    font-weight: 900;
    font-size: 3.5rem;
    line-height: 1;
    letter-spacing: 0.1em;
    margin-bottom: 1rem;
    span {
      font-size: 2.5rem;
      font-weight: 400;
    }
  }
  label {
    font-size: 1.5rem;
    padding-left: 2rem;
    margin-bottom: 1rem;
    font-family: $font-primary;
  }
  .form-select {
    padding: 1rem 5.5rem 1rem 1.6rem;
    background-color: transparent;
    color: #fff;
    font-size: 2rem;
    border-radius: 6px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 1.75V15.75M8 15.75L15 8.75M8 15.75L1 8.75' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-size: 55px 16px;
    &::placeholder {
      color: #fff;
    }
    option {
      background-color: #414141;
      border: none;
      border-color: transparent;
    }
  }

  .tag {
    font-size: 1.5rem;
    background: transparent;
    border: 2px solid #fff;
    border-radius: 5px;
    color: #fff;
    margin: 0 1.3rem 1rem 0;
    text-align: center;
    &.active {
      border-color: #fff084;
      color: #fff084;
    }
  }
}

.board-table {
  padding: 0;
  .board-header {
    padding: 8rem 9rem 2rem;
  }
  .board-content {
    padding: 0 8rem;
  }
}

@include mq(lg, max) {
  .board-table {
    .board-header {
      padding: 6rem 4rem 2rem;
    }
    .board-content {
      padding: 2rem 4rem 2rem;
    }
  }
}

@include mq(md, max) {
  .aleta {
    .right-circle::before {
      right: 4rem;
    }
    .card {
      padding: 6rem 4rem;
    }
  }

  .board {
    padding: 3rem 2rem 2rem;
    .board-title { 
      font-size: 2.5rem;
    }
  }

  .aleta .aleta-info {
    flex-direction: column;

    .aleta-media {
      margin-right: 0;
      margin-bottom: 2rem;
    }

    .aleta-item:not(:last-child) {
      padding-right: 2rem;
      border-right: 0;
    }

    .aleta-bio {
      text-align: center;
    }

    h2 {
      font-size: 3rem;
      letter-spacing: 0.1em;
    }
  }
  .board-table {
    padding: 0;
    margin: 0 -16px;
    .board-header {
      padding: 3rem 1rem 1rem;
    }
    .board-content {
      padding: 3rem 1rem 1rem;
    }
  }
}
