.noticias {
    .ranking-list-one {
        border-radius: 0;
        background: $light-color-2;
        .input-box .form-control,
        .select-box .form-control {
            background: $light-color-2;
        }
    }

    .container-fluid {
        margin-top: -8.4rem;
        position: relative;
    }

    .list-header {
        margin-bottom: 10rem;
        padding-top: 8rem;
    }
    .doc-list {
        padding: 0 5rem;
        .doc-list-item {
            font-family: $font-secondary;
            font-size: 2.2rem;
            line-height: 1.2;
            letter-spacing: 0.1em;
            margin-bottom: 1em;
            color: #253b58;
        }
    }
    .btn-mais-noticias {
        font-size: 15px;
        font-weight: 500;
        font-family: $font-secondary;
        border-width: 2px;
        border-radius: 5px;
        &:hover {
            border-width: 2px;
        }
    }
}

@include mq(lg, max) {
    .noticias {
        .post-group:last-child {
            .width-x2.height-x3 {
                order: 10;
            }
        }
    }
}

@include mq(md, max) {
    .noticias {
        .doc-list {
            .doc-list-item {
                font-size: 1.5rem;
            }
        }
    }
}
