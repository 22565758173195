.ranking-list {
  position: relative;
  .header-select {
    margin: 7rem 5rem;
  }
  .top-10 {
    .player-list {
      padding-right: 4.5rem;
    }

    .player-number {
      margin-right: 2rem;
    }

    .total-score {
      margin-left: 2rem;
    }

    hr {
      margin: 2rem 0;
    }
  }

  .top-3 {
    .player-number {
      position: absolute;
      padding: 1rem 2rem 1rem 10rem;
      border-radius: 0 10px 10px 0;
      left: 0;

      h2 {
        width: 1ch;
        font-size: 3.5rem;
        font-weight: 600;
        color: #fff;
      }
    }
    .player-list {
      padding-left: 11rem;
    }
    .text-secondary {
      color: #fff084 !important;
    }
  }
  .MuiSkeleton-root {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.ranking-list-one {
  // padding-bottom: 5rem;
  border-radius: 5px;
  overflow: hidden;
  .first-col {
    padding: 0;
    background: $primary-color;
    padding-bottom: 5rem;
    display: flex;
    flex-direction: column;
    .list-footer {
      padding: 2rem 4.5rem;
      h5 {
        font-family: $font-secondary;
        font-weight: 400;
        font-size: 1.4rem;
        letter-spacing: 0.1em;
      }
    }
  }
  .second-col {
    padding: 0;
    background-color: $primary-dark-color;
    padding: 0;
    display: flex;
    flex-direction: column;
    padding-left: 3.5rem;
    .list-footer {
      padding: 2rem 4.5rem;
      // padding-right: 5rem;
      // padding-top: 5rem;
      h3 {
        font-family: $font-primary;
        font-size: 2rem;
        letter-spacing: 0.1em;
      }
    }
  }
  .player-record,
  .player-info {
    padding-right: 4rem;
  }
  .player-record {
    display: flex;
    justify-content: space-between;
  }
  // &::before,
  // &::after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   width: 50%;
  //   height: 100%;
  //   z-index: -1;
  // }

  // &::before {
  //   left: 0;
  //   background: $primary-color;
  //   border-radius: 5px 0 0 5px;
  // }

  // &::after {
  //   left: 50%;
  //   background: $primary-dark-color;
  //   border-radius: 0 5px 5px 0;
  // }
}

.list-header {
  display: flex;
  margin-bottom: 7rem;
  padding-top: 7rem;
  justify-content: space-between;
  padding-left: 3rem;
}

.player {
  .player-info {
    margin-bottom: 3rem;
    padding-left: 6rem;
    align-items: center;
    font-family: $font-secondary;
  }

  .player-avatar {
    margin-right: 1.5rem;
    .aleta-media {
      font-size: 2rem;
    }
    .MuiSkeleton-root,
    .MuiAvatar-root {
      height: 58px;
      width: 58px;
    }
    img {
      border-radius: 50%;
    }
  }

  .player-name {
    // max-width: 15rem;
    max-width: unset;
    line-height: 1.16;
    font-size: 2rem;
    font-family: $font-secondary;
    a {
      color: #fff;
    }
    a:hover {
      opacity: 0.7;
    }
  }

  .player-nation {
    margin-left: auto;
  }

  h3 {
    font-size: 2rem;
    letter-spacing: 0.01em;
  }

  .player-score {
    width: 100%;
    // padding: 1rem 3rem;

    border-right: 1px solid;
    font-family: $font-secondary;
    // min-width: 100px;
    &:first-child {
      padding-left: 0;
    }
    &:last-of-type {
      border-right: 0;
    }

    h5 {
      font-size: 1rem;
      letter-spacing: 0.1em;
    }
    a {
      color: inherit;
    }
  }

  .total-score {
    // margin-left: auto;

    h3 {
      padding: 1rem 2.75rem;
      border: 2px solid;
      border-radius: 5px;
      font-size: 1.5rem;
      min-width: 125px;
      text-align: center;
    }
  }
  &.player-one .total-score {
    h3 {
      font-size: 2rem;
    }
  }

  img {
    max-width: 6rem;
  }
}

.player-one {
  .react-swipeable-view-container > {
    overflow: hidden;
  }
}

@include mq("1366px", max) {
  .player .player-info {
    padding-left: 6rem;
  }

  .player-list {
    padding-right: 0;
  }
  .ranking-list {
    .top-3 {
      .player-list {
        padding: 0 0 0 6rem;
      }
      .player-number {
        padding: 1rem 2rem 1rem 5rem;
      }
    }
  }
  .player .player-info {
    margin-bottom: 1rem;
  }
  .ranking-list.ranking-list-one {
    .first-col {
      padding-bottom: 0;
      .second-col {
        padding-left: 0;
      }
    }
    .second-col {
      padding-left: 0;
    }
    .player-record,
    .player-info {
      padding-right: 20px;
    }
    .top-3 .player-number {
      padding: 1rem 2rem 1rem 5.5rem;
    }
    .top-10 .player-list {
      padding: 1rem 4.5rem;
    }
  }
  // .ranking-list .top-10 .player-list {
  //   padding: 4rem 4.5rem;
  // }

  // .player .player-score {
  //   padding: 1rem 2rem;
  // }
}

@include mq(lg, max) {
  .ranking-list {
    .col-lg-6:last-child .player-list {
      padding-left: 0;
    }
    .top-3 .player-list {
      padding: 0 6rem;
    }
    .top-10 .player-list {
      padding: 0;
    }
    .header-select {
      margin: 7rem 2rem;
    }
  }

  .ranking-list-one {
    .second-col {
      padding: 4rem;
    }
    .first-col {
      padding-bottom: 2rem;
    }
    .second-col .list-footer {
      padding: 2rem 0;
    }
  }
}

@include mq(md, max) {
  .ranking-list {
    .list-header {
      flex-direction: column;
    }

    .section-title {
      margin-bottom: 3rem;
    }
  }
  .ranking-list.ranking-list-one {
    padding-bottom: 3rem !important;
    .player-list {
      padding-left: 7rem;
    }
    .first-col {
      padding: 0;
    }
    .second-col {
      padding: 0;
    }
  }

  .ranking-list {
    .top-3 {
      .player-list {
        padding-left: 2rem;
        padding-right: 0;
      }
      .player-info {
        .player-number {
          padding: 1rem 2rem 1rem 6rem;
        }
      }
    }
    .top-10 {
      .player-list {
        padding: 4rem 4rem 0;
      }
    }
    .header-select {
      margin: 0 2rem 5rem;
    }
  }
  .ranking-list-one .second-col .list-footer {
    padding: 2rem 0;
  }
  .list-header {
    padding-left: 0;
    margin: 0 2rem 4rem;
  }
  .player .player-avatar {
    .MuiSkeleton-root,
    .MuiAvatar-root {
      height: 45px;
      width: 45px;
    }
  }
}

@include mq(sm, max) {
  .ranking-section {
    padding: 0;
  }
  .player .player-info {
    padding-left: 4rem;
  }

  .ranking-list .top-3 {
    .player-number {
      padding: 1rem 1.5rem !important;
    }
    .player-list {
      padding-left: 2rem;
    }
  }
  .list-header {
    padding-left: 0;
    padding-right: 4rem;
    padding-top: 3rem;
    margin-bottom: 3rem;
  }
  .header-select {
    margin: 0 2rem 4rem;
  }
  .ranking-list-one .first-col .list-footer {
    padding-top: 0;
    padding-left: 1rem;
  }
  .first-col {
    padding-bottom: 3rem !important;
  }
  .ranking-list-one .second-col {
    padding-left: 1.5rem;
  }
  .ranking-list.ranking-list-one .top-10 .player-list {
    padding: 2rem;
    .player-nation {
      margin-left: auto;
    }
    .total-score {
      margin-left: 0.5rem;
      h3 {
        padding: 0.5em 0.5em;
      }
    }
  }
  .player.player-one .total-score h3 {
    padding: 0.5em 0.5em;
    font-size: 1.5rem;
  }

  .player {
    h3 {
      font-size: 1.5rem;
    }
    .player-name {
      font-size: 1.5rem;
    }
    .total-score {
      h3 {
        padding: 0.5em 0.5em;
        font-size: 1.5rem;
        min-width: 85px;
        text-align: center;
      }
    }
    img {
      max-width: 4rem;
    }
  }
  .ranking-list .top-3 .player-number h2 {
    font-size: 2.5rem;
  }
  .ranking-list-one .second-col .list-footer {
    padding-right: 2rem;
    padding-top: 0;
  }

  .ranking-page .ranking-list .top-3 .atleta-pts-wrapper {
    min-width: unset;
    margin-left: -3rem;
  }
}

@include mq(xs, max) {
  // .player-nation,
  // .total-score {
  //   display: none;
  // }
  .ranking-list .top-3 .player-list {
    padding-left: 3rem;
    padding-right: 0;
  }

  .player .player-name {
    max-width: none;
  }

  .header-top .logo {
    max-width: 11rem;
  }
}
