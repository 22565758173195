.post {
  max-height: 100%;
  border: 1px dashed $primary-color;

  .post-media {
    position: relative;
    width: calc(100% + 2px);
    height: 100%;
    margin: -1px -1px 0 -1px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .post-date {
    font-size: 1rem;
    letter-spacing: 0.2em;
  }

  .post-title {
    font-size: 2.4rem;
    letter-spacing: 0.1em;
  }

  .post-resumo,
  .post-resumo * {
    font-family: $font-primary !important;
    font-size: 1.4rem !important;
    text-align: left !important;
    text-decoration: none !important;
    font-style: unset !important;
    font-weight: unset !important;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 8rem;
    p {
      margin: 0 !important;
      display: inline !important;
    }
    li,
    ol {
      display: inline !important;
      margin: 0 !important;
    }
  }
  p {
    font-size: 1.4rem;
    letter-spacing: 0.1em;
  }
  .blog-post-resumo {
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 8rem;
  }

  .post-date {
    padding: 2rem 0;
  }

  .post-title {
    margin-bottom: 1.5rem;
  }

  .btn-wrapper {
    background: #fff;
    border: 4px solid #fff;
    border-bottom: 0;
    border-radius: 9px 9px 0 0;
  }

  .btn-link {
    padding: 0.5rem 2.5rem;
    background-color: $light-color-2;
    font-size: 14px;
    font-weight: 600;
    color: $secondary-color;
    letter-spacing: 0.3em;
    border: 2px solid #fff;
    border-radius: 5px 5px 0 0;
  }

  &.post-one,
  &.post-three {
    .btn-wrapper {
      position: absolute;
      bottom: 0;
    }
  }

  &.post-one {
    .post-detail {
      padding: 2rem 5.5rem;
    }

    p {
      margin-bottom: 5rem;
    }
  }

  &.post-two {
    display: flex;

    .post-media {
      height: calc(100% + 2px);
      margin: -1px;
      flex: 1;
    }
    .post-detail {
      padding: 4rem;
      flex: 0 0 50.7%;
    }
    .post-title {
      font-size: 1.6rem;
    }
  }

  &.post-three {
    border: none;
    .post-detail {
      padding: 1.4rem 0;
      margin-right: 2rem;
      border-bottom: 1px solid $primary-color;
    }
  }
  &.post-sm {
    .post-media img {
      height: 150px !important;
    }
    p {
      font-size: 1.5rem;
    }
    .post-date {
      padding: 1rem 0;
    }
    .post-detail {
      border-bottom: 1px solid #253b58 !important;
    }
  }

  &.post-four {
    border: none;
  }
}

.post-four {
  .btn-category {
    padding: 5px 2rem;
    margin: 2rem 0;
    display: flex;
    width: fit-content;
    border: 1px solid $light-color-2;
    border-radius: 5px;
    color: $light-color-2;

    .btn {
      padding: 0;
    }
  }

  h2 {
    margin-bottom: 2rem;
  }

  p {
    font-size: 1.5rem;
  }

  .btn-link {
    padding: 0.5rem 2rem;
    background: #00aeee;
    color: #000;
  }

  .post-media img {
    height: 24rem;
  }

  .btn-wrapper,
  .btn-link {
    border-radius: 5px;
  }
}

.post-group {
  &.grid {
    display: grid;
    margin: 0 -3rem;
    grid-template-columns: repeat(auto-fill, calc(100% / 12));
    grid-template-rows: repeat(auto-fill, calc(100% / 12));
    grid-auto-rows: auto;

    .grid-item {
      padding: 1rem;
    }
  }

  .height-x1 {
    grid-row-end: span 5;
  }

  .height-x2 {
    grid-row-end: span 6;
  }

  .height-x3 {
    // height: 66rem;
    grid-row-end: span 12;
  }

  .width-x1 {
    grid-column-end: span 3;
  }

  .width-x2 {
    grid-column-end: span 6;
  }
}

@include mq("1366px", max) {
  .post.post-one p {
    margin-bottom: 4rem;
  }

  .post.post-one .post-detail {
    padding: 1rem 2.5rem;
  }
}

@include mq("lg", max) {
  .height-x3.width-x2 {
    grid-column-end: span 12;
  }
  .height-x1.width-x1 {
    grid-column-end: span 6;
    grid-row-end: span 2;
  }
  .post.post-two {
    flex-direction: column;

    .post-detail {
      padding: 2rem;
    }
  }
  .btn-wrapper {
    position: absolute;
    bottom: 0;
  }
  .post.post-two {
    .post-detail {
      .btn-link {
        display: none;
      }
    }
  }
  .post-group {
    .post {
      border-color: transparent;
      img {
        min-height: unset;
      }
      .post-detail {
        padding: 2rem;
        margin-right: 2rem;
        border-bottom: 1px solid #253b58;
      }
    }
  }
}

@include mq("sm", max) {
  .width-x2.height-x3 {
    img {
      min-height: 40rem;
    }
  }

  .width-x2.height-x2,
  .width-x1.height-x1 {
    img {
      max-height: 25rem;
    }
    grid-column-end: span 12;
  }
  .post-group {
    .post {
      img {
        min-height: unset;
      }
    }
  }
}

@media (min-width: 991px) {
  .post-group {
    .post.post-one {
      img {
        height: 380px;
      }
      .post-detail {
        height: 310px;
      }
    }
    .post.post-two {
      img {
        height: 380px;
      }
      figure {
        .btn-wrapper {
          display: none;
        }
      }
    }
    .post.post-three {
      img {
        height: 195px;
      }
      .post-detail {
        height: 100px;
      }
    }
  }
}
