.newsletter-section {
    padding: 10rem 0 10rem;

    h2 {
        font-size: 4rem;
    }

    h5 {
        font-size: 1.3rem;
        letter-spacing: 0.015em;
    }

    .container {
        padding-right: 20rem;
    }

    .newsletter-title {
        display: flex;
        margin-bottom: 2rem;
        align-items: center;
        justify-content: space-between;
    }

    .newsletter-content {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: space-between;

        .btn-out.btn-wrapper {
            left: 104%;
            border-color: $primary-color;
        }
    }

    .form-control {
        padding: 2rem;
        font-size: 2rem;
        border-radius: 6px;
    }
}

.blog-logo {
    padding: 9rem 0 3.5rem;
}

.footer-divider {
    display: flex;
    margin: 15rem 0;

    h3 {
        color: #00287b;
        font-size: 1.5rem;
        letter-spacing: 0.3em;
    }

    hr {
        flex: 1;
        margin-left: 3rem;
        color: #00287b;
    }
}

.footer-middle {
    display: flex;
    margin-bottom: 17rem;
    justify-content: space-between;

    img {
        object-fit: contain;
    }
}

.footer-bottom {
    position: relative;
    padding: 9rem 0;
    background: #ebebeb;

    .container {
        display: flex;
        position: relative;
        justify-content: space-between;
        z-index: 1;
    }

    p {
        font-size: 1.5rem;
        letter-spacing: 0.015em;
    }

    img {
        display: block;
    }

    .social-icons {
        margin-bottom: 4.5rem;

        .social-icon {
            margin-right: 6rem;
            font-size: 2.5rem;
        }
    }
}

.footer-frame {
    position: absolute;
    top: -8%;
    left: 22%;
    transform: scale(1.3);
}

.footer-right {
    img:first-child {
        margin-bottom: 11rem;
    }

    img:last-child {
        margin-left: 5rem;
    }
}

@include mq(lg, max) {
    .footer-middle {
        flex-wrap: wrap;
        justify-content: space-around;
        img {
            max-width: 100%;
            padding: 10px 0;
        }
    }
    .newsletter-section .form-control {
        padding: 1rem;
    }
    .footer-divider {
        margin-bottom: 4rem;
    }
}

@include mq(md, max) {
    .newsletter-section {
        padding: 7rem 10px 14rem;

        .newsletter-content .btn-out.btn-wrapper {
            left: 0;
            top: 150%;
        }

        .newsletter-section .form-control {
            padding: 1rem;
        }

        .container {
            padding-right: 1rem;
        }

        .newsletter-content {
            display: block;
            .form-control {
                padding: 0.75rem 1.75rem;
                margin-bottom: 1rem;
            }
        }
    }

    .footer-right {
        img:first-child {
            max-width: 28rem;
            margin-bottom: 3rem;
        }

        img:last-child {
            margin-left: 0;
        }
    }
}

@include mq("660px", max) {
    .footer-left {
        margin-bottom: 3rem;
    }

    .footer-bottom .container {
        flex-direction: column;
    }
}

@include mq(sm, max) {
    .newsletter-title {
        flex-direction: column;
    }
    .footer-bottom{
        img{
            left: 0;
            transform: scale(.95);
        }
    }
}
