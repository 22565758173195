.header-top {
  background-color: $gray-color;

  .header-right {
    margin-bottom: -2rem;
  }

  .container {
    display: flex;

    justify-content: space-between;
  }

  .social-icon {
    margin-right: 5rem;
    font-size: 19px;
  }

  .login {
    font-size: 12px;
  }

  .logo {
    position: relative;
    transform: translateY(3.5rem);
    z-index: 10;
  }
}

.header {
  .main-nav {
    justify-content: space-around;
    padding: 0 4rem;
    width: 100%;
    li {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
    }
  }
  .dropdown {
    a {
      padding: 0;
    }
  }
  .dropdown-menu {
    padding: 0.6rem 0;
    background-color: #327fe4;
    &.mega-menu.show {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding: 1.2rem 2rem;
      min-width: 500px;
      .dropdown-item {
        min-width: unset;
      }
      .mega-menu-item {
        width: 100%;
        h4 {
          color: #fff;
          font-size: 1.5rem;
          margin-bottom: 0.5em;
          font-weight: 700;
          font-family: $font-primary;
        }
      }
    }
    .dropdown-item {
      color: #fff;
      padding: 0.6rem 1.5rem;
      font-family: $font-secondary;
      &:hover {
        background-color: rgba(255, 255, 255, 0.4);
      }
    }
  }
}

.header-middle {
  .container {
    padding: 2.5rem 0;
  }
}

.header-bottom {
  padding: 3rem 0 2rem;
}

.logo-text {
  object-fit: contain;
}
.logo-tablet,
.logo-mobile {
  display: none;
  height: 180px;
  object-fit: contain;
  padding: 10px 20px 10px 10px;
  max-width: 100%;
}
@include mq(lg, max) {
  .logo,
  .logo-text {
    display: none;
  }
  .logo-tablet {
    display: block;
  }
  .header {
    .dropdown-menu {
      &.mega-menu.show {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        .dropdown-item {
          min-width: 160px;
        }
      }
    }
  }
  // .header .main-nav li:not(:last-child) {
  //     margin-right: 6.5rem;
  // }
}

@include mq(md, max) {
  .header {
    .main-nav {
      padding: 0 2rem;
      li:not(:last-child) {
        margin-right: 1.5rem;
      }
    }
    .dropdown-menu {
      &.mega-menu.show {
        display: grid;
        grid-template-columns: 1fr 1fr;
        .dropdown-item {
          min-width: 100px;
        }
      }
    }
  }
}

@include mq(sm, max) {
  .header-top {
    .logo {
      max-width: 12rem;
    }

    .social-icon {
      margin-right: 2rem;
    }

    .container {
      padding: 0;
      padding-right: 1rem;
    }
  }
  .logo-mobile {
    display: block;
  }
  .logo-tablet {
    display: none;
  }
  .header-middle {
    padding: 0 1rem;
    .container {
      padding: 2rem 0;
    }
    .navbar-nav {
      li {
        margin-bottom: 0.5rem;
      }
    }
    // display: none;
  }
  .header {
    .dropdown-menu {
      &.mega-menu.show {
        min-width: auto;
        grid-template-columns: 1fr;
      }
    }
  }
}
