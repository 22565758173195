.transparencia {
  .ranking-list-one {
    &::before,
    &::after {
      background: $green-color;
      border-radius: 0;
    }

    h2 {
      margin-bottom: 1.5rem;
    }

    h5 {
      font-size: 1.3rem;
      font-weight: 400;
      letter-spacing: 0.2em;
    }
  }

  .transparencia-header {
    background-color: $green-color;
    border-radius: 0;
    .list-header {
      margin-bottom: 11rem;
    }
  }

  .accordion {
    margin-top: -6rem;
    border-radius: 6px;
  }
}
