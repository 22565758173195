.event-group {
  &.grid {
    display: grid;
    margin: -1.4rem;
    grid-template-columns: repeat(auto-fill, calc(100% / 6));
    grid-template-rows: repeat(auto-fill, calc(100% / 6));
    grid-auto-rows: auto;

    .grid-item {
      grid-column-end: span 2;
      padding: 3.5rem;
    }
  }

  .height-x1 {
    grid-row-end: span 3;
  }

  .height-x2 {
    grid-row-end: span 6;
  }
}

.events-list {
  table thead th {
    font-family: "Lato", sans-serif;
  }
  table tbody td .container-border {
    min-height: 70px;
    border-right: 1px solid $light-color-2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.event-group-two {
  color: $primary-light-color-2;
  a {
    font-family: "DM Sans", sans-serif;
    font-weight: 500;
    color: $primary-light-color-2;
    line-height: 1.1;
    &:hover {
      color: $primary-color;
    }
  }

  .event-group-header {
    display: flex;
    position: relative;
    padding-top: 11rem;
    margin-bottom: 7.5rem;
    color: $primary-light-color-2;

    h3 {
      font-family: "Lato", sans-serif;
      display: flex;
      margin-bottom: 1rem;
      font-size: 3.5rem;
      font-weight: 600;
      letter-spacing: 0.1em;
      align-items: center;
    }

    i {
      font-size: 2rem;
    }

    input,
    select {
      border-radius: 6px 6px 0 0;
      border: 2px solid $primary-light-color-2;
      border-bottom: 0;
      font-size: 1.2rem;
      color: $primary-light-color-2;
      font-weight: 600;
      letter-spacing: 0.2em;
      text-transform: uppercase;
    }

    input {
      max-width: 29rem;
    }

    select {
      position: relative;
      max-width: 22rem;

      &::before {
        content: "\f063";
        display: inline-block;
        position: absolute;
        bottom: 20%;
        right: 2rem;
        font-family: "Font Awesome 6 Free";
        font-size: 18px;
        font-weight: 900;
        color: #fff;
      }
    }

    .form-select {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='15' height='7' viewBox='0 0 15 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.75 1.85498L7.75 5.57599L13.75 1.85498' stroke='%23256AC3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }
    .form-search {
      display: block;
      width: 100%;
      // padding: 0.375rem 2.25rem 0.375rem 0.75rem;
      -moz-padding-start: calc(0.75rem - 3px);
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 17.4207L13.2542 13.6748M15.2778 8.80954C15.2778 12.6142 12.1935 15.6984 8.38889 15.6984C4.58426 15.6984 1.5 12.6142 1.5 8.80954C1.5 5.00491 4.58426 1.92065 8.38889 1.92065C12.1935 1.92065 15.2778 5.00491 15.2778 8.80954Z' stroke='%23256AC3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 0.75rem center;
      background-size: 25px 22px;
      min-width: 35ch;
    }

    .event-header-background {
      position: absolute;
      width: 100%;
      top: -34%;
      bottom: 0;
      opacity: 0.6;
      z-index: -1;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .container-fluid {
      display: flex;
      border-bottom: 2px solid $primary-light-color-2;
    }
  }

  .container-fluid {
    padding: 0;
  }

  thead tr {
    font-size: 1.2rem;
    letter-spacing: 0.3em;
  }

  h5 {
    font-size: 1.8rem;
  }

  table {
    margin-bottom: 11rem;
  }
}

.event {
  .score {
    padding: 4.5rem 3rem 4.5rem 0;
    background-color: $primary-color;
    color: #fff;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    // width: 14rem;
    h5 {
      display: flex;
      margin-left: -2rem;
      padding: 2rem 0;
      border: 2px solid #fff;
      border-radius: 1rem;

      span {
        margin-left: auto;
        padding-right: 2rem;
      }
    }
  }

  .text-wrapper {
    padding: 1rem 2rem;
    width: 100%;
    text-align: left;
  }

  thead th {
    padding-bottom: 2rem;
    font-size: 1.2rem;
    letter-spacing: 0.3em;
  }

  tbody td {
    position: relative;

    & {
      border-top: 1px solid $light-color-2;
      border-bottom: 1px solid $light-color-2;
    }

    &.event-title {
      &::after,
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 10px;
        left: 0;
        height: 1px;
        background-color: #fff;
      }

      &::before {
        top: -1px;
      }
      &::after {
        bottom: -1px;
      }
      // &::after {
      //     bottom: 0;
      // }
    }
  }
}

.event-three {
  .text-wrapper {
    h5 {
      font-family: "Lato", sans-serif;
      margin-bottom: 0.5rem;
    }

    h6 {
      font-family: "DM Sans", sans-serif;
      font-size: 1.4rem;
    }
  }
  .evento-nacional {
    background-color: #90a607;
  }
  .evento-internacional {
    background-color: #114fa0;
  }
  .evento-estadual {
    background-color: #8b1ea6;
  }
  .evento-local {
    background-color: #0aa1f6;
  }
}

@include mq(lg) {
  .event-group-two .event-group-header select {
    width: 22rem;
  }
}

@include mq(lg, max) {
  .event-group.grid .grid-item {
    grid-column-end: span 3;
  }

  .event {
    .text-wrapper {
      border: 0;
    }

    .score {
      padding: 4.5rem 2rem 4.5rem 0;
      // width: 11rem;
      h5 span {
        padding-right: 1rem;
      }
    }
  }
}

@include mq(md, max) {
  .event-group.grid .grid-item {
    grid-column-end: span 6;
  }

  .event {
    thead {
      th:nth-child(3),
      th:nth-child(4) {
        display: none;
      }
    }

    tbody {
      td:nth-child(3),
      td:nth-child(4) {
        display: none;
      }
    }
  }
  .event-group-two {
    .event-group-header {
      .container-fluid {
        flex-direction: column;
      }
    }
  }
}

@include mq(sm, max) {
  .event-three .score {
    // width: 11rem;
    padding: 2.5rem 1.5rem 2.5rem 0;
  }
}
