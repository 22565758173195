.gallery {
    padding-top: 20rem;
    margin-top: -12rem;
    padding-bottom: 6rem;
    background-color: $primary-light-color;

    img {
        width: 100%;
        height: 100%;
        max-height: 25rem;
    }
}

.overlay-dark {
    position: relative;
    cursor: pointer;

    // &::before {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     bottom: 0;
    //     left: 0;
    //     background: #000;
    //     opacity: 0;
    //     transition: opacity .35s;
    //     z-index: 1;
    // }

    // &:hover::before {
    //     opacity: .25;
    // }
}

.gallery {
    
    .overlay-zoom,
    .overlay-dark {
        background-color: #000;
        img {
            transition: opacity 0.35s, transform 0.35s;
        }
        &:hover img {
            opacity: 0.75;
            transform: scale(1.1);
        }
    }
}

.overlay-zoom {
    overflow: hidden;

    img {
        transform: scale(1);
        transition: transform 0.35s;
        overflow: hidden;
    }

    &:hover img {
        transform: scale(1.1);
    }
}
