.noticias-one {
  .section-title {
    margin-bottom: 7rem;
    padding: 8rem 0 5rem;
  }

  .tags-title {
    margin-bottom: 4rem;
    font-size: 13px;
    letter-spacing: 0.2em;
  }

  .tag-group {
    display: flex;
    flex-wrap: wrap;
  }

  .tag {
    font-family: $font-primary;
    font-weight: 700;
    padding: 1.2rem 3.2rem;
    margin-bottom: 2rem;
    margin-right: 3.3rem;
    font-size: 2.2rem;
    letter-spacing: 0.1em;
    line-height: 1;
    background-color: $light-color-2;
    border-radius: 10rem;
    color: #fff;
    white-space: nowrap;
    text-transform: uppercase;
    &:hover {
      background-color: $primary-color;
    }
    &.active {
      background-color:  $primary-color;
    }
  }

  .note-wrapper {
    margin-bottom: 6rem;
    font-size: 2.2rem;
    letter-spacing: 0.1em;
  }

  .note-title {
    font-weight: 600;
    padding-bottom: 2.4rem;
    border-bottom: 2px solid $primary-color;
  }

  .notes {
    margin-top: 5rem;
  }

  .note {
    margin-bottom: 2rem;
  }

  .doc-group {
    padding: 0 2rem;
    &:nth-child(odd) {
      // padding-right: 5rem;
      border-right: 1px dotted $primary-color;
    }

    // &:last-child {
    //   padding-left: 5rem;
    // }
  }

  .row {
    margin: 6rem 0 4rem;
  }
}

@include mq(lg, max) {
  .noticias-one {
    .tag {
      margin-right: 1rem;
      margin-bottom: 1rem;
      font-size: 1.5rem;
    }
  }
}

@include mq(md, max) {
  .noticias-one {
    .col-md-6 {
      padding: 0 !important;
      border: 0 !important;
    }
    .tag {
      margin-right: 1rem;
      margin-bottom: 1rem;
      font-size: 1.5rem;
    }
  }
}
