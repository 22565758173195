.card {
  position: relative;
  margin-bottom: 2rem;
  background: #e3e3e3;
  color: $light-color-2;

  .card-title {
    position: relative;
    cursor: pointer;

    // &::before {
    //   content: "\f056";
    //   font-family: "Font Awesome 6 Free";
    //   display: block;
    //   position: absolute;
    //   top: 50%;
    //   left: 0;
    //   font-size: 2.3rem;
    //   font-weight: 600;
    //   transform: translateY(-50%);
    // }
  }

  // &:not(.collapsed) {
  //   .card-title {
  //     margin-bottom: 2rem;
  //   }
  // }
  .custom-table {
    h4 {
      font-family: $font-secondary;
    }
    .td.dl span {
      font-family: $font-primary;
      letter-spacing: 0.1em;
    }
  }
  &.card-one {
    padding: 5rem 7rem;
    border-radius: 6px;
    .card-title {
      svg {
        height: 27.5px;
        width: 27.5px;
        margin-right: 2.8rem;
        circle,
        path {
          stroke: $light-color-2;
          stroke-width: 3px;
        }
      }
    }
    h2 {
      font-family: $font-secondary;
      font-weight: bolder;
      display: flex;
      align-items: center;
      padding-left: 10px;
      font-size: 3.5rem;
      letter-spacing: 0.1em;
    }
  }

  &.card-two {
    padding: 2rem 0;
    margin-bottom: 0;
    border-top: 1px dotted #000;
    border-width: 1px 0 0;
    border-radius: 0;

    .card-title {
      svg {
        height: 23px;
        width: 23px;
        margin-right: 2.8rem;
      }

      &::before {
        color: #8b1ea6;
      }
    }

    &:last-child {
      border-bottom: 1px dotted #000;
    }
    h2 {
      font-family: $font-secondary;
      font-weight: bold;
      font-size: 2rem;
      letter-spacing: 0.2em;
      color: #424242;
    }
    .card-body {
      border: 2px solid #8b1ea6;
      border-radius: 6px;
      padding: 2.5rem 3rem;
      font-size: 1.5rem;
      font-family: $font-secondary;
      color: #424242;
      line-height: 1.2;
      margin: 10px 0 10px 50px;
      table {
        width: 100%;
        padding: 1.5rem;
      }

      thead {
        width: 100%;
        background-color: $primary-color;
      }
    }
  }
  .card-title {
    .plus-vertical {
      transition: all 250ms ease-in-out;
      opacity: 0;
      // transform: rotate(90deg) translate(0px, -25px);
    }
  }
  &.collapsed .card-title {
    .plus-vertical {
      opacity: 1;
    }
  }
  // &.collapsed .card-title::before {
  //   content: "\f055";
  // }

  h4 {
    font-size: 1.4rem;
  }
}

.card-federacao.MuiCard-root {
  margin-bottom: 2rem;
  color:#424242;
  h4 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1;
    font-family: $font-secondary;
  }
  h5 {
    font-family: $font-secondary;
  }
}

@include mq(md, max) {
  .card {
    .card-title h2 {
      font-size: 2.4rem;
    }
    &.card-one {
      padding: 2rem;
      .card-title {
        svg {
          height: 22.5px;
          width: 22.5px;
          margin-right: 1.5rem;
          circle,
          path {
            stroke: $light-color-2;
            stroke-width: 2px;
          }
        }
      }
      .table-row {
        padding: 1rem;
        .td.dl {
          margin-top: auto;
        }
      }
      .custom-table h4 {
        white-space: unset;
      }
    }
  }
}

@include mq(sm, max) {
  .card {
    .card-title {
      h2 {
        font-size: 2rem;
      }
    }
  }
}
