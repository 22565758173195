.breadcrumb {
    padding: 3.6rem 0 2.4rem;
    margin: 0;
    span,
    a {
        font-size: 1.2rem;
        letter-spacing: 0.2em;
        font-family: $font-primary;
        margin: 0 0.5rem;
        color: $primary-light-color-2;
    }
    a {
        font-weight: 900;
        &:hover {
            color: $primary-color;
        }
    }

    // span {
    //     display: inline-block;
    //     margin: 0 .5rem;
    //     font-size: 12px;
    // }
}
