.evento-tabs {
  .MuiButtonBase-root {
    font-size: 16px;
    font-weight: 700;
    font-family: "DM Sans", sans-serif;
    letter-spacing: 0.1em;
  }
  .MuiButtonBase-root.Mui-selected {
    color: #8b1ea6;
  }
  .MuiTabs-indicator {
    background-color: #8b1ea6;
  }
}
@include mq(md, max) {
  .evento-tabs {
    .MuiButtonBase-root {
      font-size: 14px;
      letter-spacing: unset;
    }
  }
}
