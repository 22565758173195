.filter-galeria {
    margin: 0 0 40px;
    padding: 40px 0 80px;
    color: white;
    background-color: $green-color;
    .filter-container {
        text-transform: uppercase;
    }
    .title-filtrar {
        color: white;
        margin-bottom: 10px;
        font-size: 4rem;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-weight: 600;
        font-family: $font-primary;
    }
    .filter-buttons {
        & svg {
            margin: 1.5rem;
        }
        & hr {
            margin: 0.6rem;
        }
        & .Mui-disabled {
            opacity: 0.7;
        }
    }
    .divider {
        background-color: white;
        width: 2px;
        height: 2.2rem;
    }
    .btn-filtros {
        margin: 0rem 1.5rem;
        padding: 0.6rem 0.6rem;
        text-transform: uppercase;
        font-size: 1.5rem;
        font-weight: 600;
        letter-spacing: 0.05em;
        &:hover {
            color: #fff;
            opacity: 0.7;
        }
    }
}

.img-container {
    button{
        width: 100%;
    }
    & img {
        width: 100%;
        height: 250px;
        object-fit: cover;
    }
}
.grid-galeria {
    margin-top: -100px;
    margin-bottom: 5rem;
    .card-galeria {
        background-color: transparent;
        .card-content {
            padding-left: 0;
            padding-right: 0;
        }
        .data {
            font-size: 1.2rem;
            color: $primary-color;
        }
        .titulo-galeria {
            font-size: 1.5rem;
            font-weight: bold;
            color: $primary-color;
            cursor: pointer;
            text-decoration: none;
            &:hover {
                color: $primary-light-color-2;
            }
        }
    }
    .pagination-wrapper {
        margin-top: 2rem;
    }
}


@include mq(md, min) {
    .TitleFiltrar {
        font-size: 1.2rem;
        margin-bottom: 0;
    }
    .BtnFiltros {
        font-size: 1.2rem;
    }
    .filterContainer {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .paginationContainer {
        justify-content: flex-end;
    }
}
