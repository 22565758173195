.schedule-text {
    margin-bottom: 8rem;
}

.schedule {
    padding: 7rem 0 17rem;
    background: $gray-color;

    figure {
        position: relative;
        margin-bottom: 2rem;
    }

    .light-bar::before {
        top: -8rem;
    }

    .score {
        margin-bottom: 0;
        padding: 0.5em 2.5em;
        color: #fff;
        font-size: 1.8rem;
        letter-spacing: 0.3em;
        border-radius: 5px 5px 0 0;
        border-bottom: none;
        border-color: transparent;
    }

    h2,
    h3,
    h5 {
        margin-bottom: 1rem;
    }

    h3 {
        font-size: 1.8rem;
        letter-spacing: 0.1em;
    }

    h5 {
        font-size: 1.2rem;
        letter-spacing: 0.2em;
        color: $gray-color-2;
    }

    .section-title h2 {
        margin-bottom: 4.5rem;
    }

    .event-one {
        border-bottom: 1px solid $primary-color;
        .score {
            position: absolute;
            left: 0;
            bottom: 0;
            background: $green-color;
        }
        .event-media {
            // background-color: #fff;
            // width: 100%;
            img {
                max-height: 240px;
            }
        }
    }

    .score.bg-green{
        background: $green-color;
    }
    .score.bg-blue{
        background: $light-color-2;
    }

    .event-two {
        border-bottom: 1px solid $primary-color;

        h2 {
            width: fit-content;
            margin-bottom: 2.5rem;
            border-radius: 5px 5px 0 0;
        }
    }
}

@include mq(md, max) {
    .schedule .section-title h2 {
        margin-bottom: 7.5rem;
    }
}
